import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import '../../../assets/styles/AccountInfo.css';
import UserContext from '../../../utils/Contexts/userContext.js';
import videoSource from '../../../assets/videos/video-add-bizness-tutorial.mp4';

export default function DocsView() {

  const { user } = useContext(UserContext);

  return (
    <>
    <Container style={{ minHeight: '85vh' }}>
      <div className='pt-5'>
        <p className="paragraph-text">Hi <span className='biz-color'>{user.firstName} {user.lastName}</span>! Welcome to Documentation Page!</p>
        <p className="paragraph-text">This is your agent ID, <span className='biz-color'>{user._id}</span>.</p>

      </div>

      <div className='py-5'>
            <h1>Proper Setup for Creating a Biz-ness!</h1>
            <p className="paragraph-text">By: BizSolutions Development Team | Published: December 31, 2024</p>
            <hr/>

            <p className="paragraph-text">Learn how to add your successful business in our database.</p>

            <video className="h-full w-full pb-5 rounded-lg" controls controlsList="nodownload">
            <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <p className="paragraph-text">
                If error persists, please don't hesitate to message or send us an email, <span className='biz-color'>webdev@bizsolutions.us</span>. 
            </p>

      </div>

    </Container>
  </>
  );
}
