import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import BarSpinner from '../Shared/Spinner/BarSpinner';

const apiUrl = process.env.REACT_APP_API_URL;

export default function AccountDetails() {
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [message, setMessage] = useState('You have no subscription yet.');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const getSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/v1/subscribers/subscription/retrieve/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.subscriptionData) {
          // Add the subscription data directly to the state
          setSubscriptions([response.data.subscriptionData]);
        } else {
          setMessage('No subscriptions found for this user.');
        }
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setMessage('Failed to retrieve subscriptions.');
      } finally {
        setLoading(false);
      }
    };

    getSubscriptions();
  }, [token]);

  if (loading) {
    return <BarSpinner />;
  }

  return (
    <Container className="pb-5">
      <div className="my-3">
        <h2>Subscriptions</h2>
      </div>
      <hr />
      {subscriptions.length > 0 ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Product</th>
              <th>Amount</th>
              <th>Package</th>
              <th>Month</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.map((sub, index) => (
              <tr key={index}>
                <td>{sub.subscriptionName}</td>
                <td>${(sub.subscriptionValue / 100).toFixed(2)}</td>
                <td>{sub.subscriptionPackage}</td>
                <td>{sub.subscriptionMonth}</td>
                <td>
                  <a href={sub.urlLink} target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>{message}</p>
      )}
    </Container>
  );
}
