import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Heading,
  Container,
} from '@chakra-ui/react';
import { FaCheck } from "react-icons/fa6";
import { GoDash } from "react-icons/go";

const features = [
  "Management of Social Media Platforms",
  "Social Media/Profile Optimization",
  "Strategy Development",
  "Social Media Audit",
  "Posting Frequency",
  "Graphics/Content Creation",
  "Video Editing",
  "Copywriting/Caption Writing",
  "Hashtag & Keyword Research",
  "Analytics",
  "Daily Engagement/Community Management",
  "Paid Advertising Support",
  "Consultation Calls",
  "Custom Platform Features",
];

const packageDetails = {
  standard: new Set([
    "Management of Social Media Platforms",
    "Social Media/Profile Optimization",
    "Strategy Development",
    "Social Media Audit",
    "Posting Frequency",
    "Graphics/Content Creation",
    "Video Editing",
    "Copywriting/Caption Writing",
    "Hashtag & Keyword Research",
    "Analytics",
    "Daily Engagement/Community Management",
  ]),
  premium: new Set([
    "Management of Social Media Platforms",
    "Social Media/Profile Optimization",
    "Strategy Development",
    "Social Media Audit",
    "Posting Frequency",
    "Graphics/Content Creation",
    "Video Editing",
    "Copywriting/Caption Writing",
    "Hashtag & Keyword Research",
    "Analytics",
    "Daily Engagement/Community Management",
    "Paid Advertising Support",
    "Consultation Calls",
    "Custom Platform Features",
  ]),
};

const Catalogue = () => (
  <Container maxW="container.xl" p={4}>
    <Box textAlign="center" mb={6}>
      <Heading as="h2" size="md">Features Included</Heading>
    </Box>
    <TableContainer overflowX="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th fontSize="sm">Feature</Th>
            <Th textAlign="center" fontSize="sm">Standard Package</Th>
            <Th textAlign="center" fontSize="sm">Premium Package</Th>
          </Tr>
        </Thead>
        <Tbody>
          {features.map((feature, index) => (
            <Tr key={index}>
              <Td fontSize="sm" color="blue.900">{feature}</Td>
              <Td textAlign="center">
                {packageDetails.standard.has(feature) ? (
                  <FaCheck color="orange" />
                ) : (
                  <GoDash color="gray" />
                )}
              </Td>
              <Td textAlign="center">
                {packageDetails.premium.has(feature) ? (
                  <FaCheck color="orange" />
                ) : (
                  <GoDash color="gray" />
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  </Container>
);

export default Catalogue;
