import React from 'react';
import { Container } from 'react-bootstrap';
import SocialMediaPackage from '../SocialMedia/Main.js';
import WebDevPackage from '../WebDevelopment/Main.js';

export default function Pricing_SectionF() {

    return (
        <>
            <Container >
                <hr/>
                <div className='pb-lg-3'>
                    <div className='text-center'>
                        <h2>Social Media Package</h2>
                    </div>
                    <div className='container'>
                        <SocialMediaPackage />
                    </div>
                </div>
            </Container>
        </>

    );
};