import React, { useContext } from 'react';
import { Card, Badge, Button, CardHeader, Heading, Flex, Box, CardFooter, CardBody, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import userContext from '../../../../utils/Contexts/userContext';
import useCountingEffect from '../TypingEffects/Amount';

export default function OptionASocialMedia({ isAnnual }) {
    const { user } = useContext(userContext);
    const navigate = useNavigate();

    const stripePaymentLinks = {
        monthly: "https://buy.stripe.com/6oE4hw2Abh030DK4gV",
        annual: "https://buy.stripe.com/bIYdS65Mn115cms28u"
    };

    const handleGetStarted = () => {
        const token = localStorage.getItem('token');
        if (user?._id && token) {
            const paymentLink = isAnnual ? stripePaymentLinks.annual : stripePaymentLinks.monthly;
            window.location.href = paymentLink;
        } else {
            navigate('/login');
        }
    };

    const price = isAnnual ? 199.99 : 199.99;
    const displayedPrice = useCountingEffect(price);

    return (
        <Card maxW="md">
            <CardHeader>
                <Flex spacing="4">
                    <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                        <Box>
                            <Heading size="sm" color="gray.800">Standard Setup</Heading>
                            <Heading size="lg" ps={5} color="#FF851A">
                                ${displayedPrice}
                            </Heading>
                            <Flex alignItems="center" mt={2}>
                                <Heading size="sm" ps={5} color="#FF851A" mr={2}>per month</Heading>
                                <Badge fontSize="0.8em" colorScheme="green">
                                    {isAnnual ? 'billed annually' : 'billed monthly'}
                                </Badge>
                            </Flex>
                            <Heading size="sm" color="gray.400">Boost Productivity, Unlock Performance</Heading>
                        </Box>
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody>
                <Text fontSize="sm" color="gray.800">
                    Unlock Your Business Potential! Manage 1 social media platform, get 3 posts per week, social media optimization, strategy development, video editing, analytics, and daily engagement.
                </Text>
            </CardBody>
            <CardFooter
                justify="space-between"
                flexWrap="wrap"
                sx={{
                    '& > button': {
                        minW: '136px',
                    },
                }}
            >
                <Button flex="1" variant="ghost" onClick={handleGetStarted}>
                    Get Started
                </Button>
            </CardFooter>
        </Card>
    );
}
