import React, { useContext } from 'react';
import { Card, Button, CardHeader, Heading, Text, Flex, Box, CardFooter, CardBody } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import userContext from '../../../../utils/Contexts/userContext';

export default function OptionBSocialMedia() {
    const { user } = useContext(userContext);
    const navigate = useNavigate();
    const emailSubject = encodeURIComponent("Inquiry about Premium Setup");
    const emailBody = encodeURIComponent(
        `Hi,\n\nI am interested in learning more about your Premium Setup package. Please provide further details.\n\nThank you.`
    );
    const mailToLink = `mailto:webdev@bizsolutions.us?subject=${emailSubject}&body=${emailBody}`;

    const handleGetStarted = () => {
        const token = localStorage.getItem('token');
        if (user?._id && token) {
            window.location.href = mailToLink;
        } else {
            navigate('/login');
        }
    };

    return (
        <Card maxW="md">
            <CardHeader>
                <Flex spacing="4">
                    <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                        <Box>
                            <Heading size="md" color="gray.800">Premium Setup</Heading>
                            <Heading size="xl" ps={5} color="#FF851A">Contact Us</Heading>
                            <Heading size="sm" color="gray.400">Boost your business with key features</Heading>
                        </Box>
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody>
                <Text fontSize="sm" color="gray.800">
                    Unlock your potential with our Premium Package: manage 3 platforms, advanced optimization, 5 posts weekly, video editing, premium content, daily engagement, and more. Contact us to learn more!
                </Text>
            </CardBody>
            <CardFooter
                justify="space-between"
                flexWrap="wrap"
                sx={{
                    '& > button': {
                        minW: '136px',
                    },
                }}
            >
                <Button flex="1" variant="ghost" onClick={handleGetStarted} style={{ color: '#FF851A' }}>
                    Contact Us
                </Button>
            </CardFooter>
        </Card>
    );
}
